.PSPDFKit-Form-Assigned-User
  padding: 0 var(--PSPDFKit-spacing-4)
  margin-top: var(--PSPDFKit-spacing-6)

  label
    width: 100%

  select
    padding: var(--PSPDFKit-spacing-6)
    width: 100%

.PSPDFKit-Form-Assigned-User-Error-Message
  color: #E84C51
  padding-left: 0.25rem
  padding-top: 0.0725rem
  font-size: 0.75rem
  text-align: left

.PSPDFKit-Form-Assigned-User-Initials
  position: absolute
  right: -13px
  top: -13px
  width: 26px
  height: 26px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 50px
  color: #FFF
  background: #01A0C6
  font-size: 12px

.PSPDFKit-Form-Sign-Block
  background: rgba(236,240,255,.9)
  display: block
  width: 100%
  height: 100%

.PSPDFKit-Text-Field-Sign-Label
  display: inline-flex
  align-items: center
  background-color: var(--PSPDFKit-Signatures-background)
  border: 1px solid var(--PSPDFKit-Signatures-border)
  border-radius: 0 0 0 0.2em
  font-size: var(--PSPDFKit-fontSize-2)
  color: #FFF
  right: 0
  line-height: 1
  padding: 0.2em 0.4em
  position: absolute
  text-transform: lowercase

.PSPDFKit-Annotation-Widget-Signature
  span
    left: initial
    right: 0
    border-radius: 0 0 0 0.2em

.jump-to-next-button, .finish-button
  margin-left: auto

svg
  vertical-align: initial

.PSPDFKit-Modal-Dialog[aria-label="Signature"]
  & > p:first-child
    margin-top: 0.8rem
    margin-bottom: 0.8rem

.PSPDFKit-Form-Creator-Toolbar button:not(.PSPDFKit-Form-Creator-Toolbar-TextWidget):not(.PSPDFKit-Form-Creator-Toolbar-SignatureWidget)
  display: none

.PSPDFKit-Annotation-Widget-Text
  font-size: 16px !important

.BaselineUI-Modal-Content
  label
    display: flex
    margin-bottom: 0

@media (max-height: 300px)
  .PSPDFKit-Electronic-Signatures-Tabs-Container
    height: calc(100% - 55px)
    display: flex
    flex-direction: column

    > :nth-child(2)
      overflow-y: auto

    > :last-child
      padding: 10px 20px

.d-none
  display: none
